<template>
    <div class="single-sourcing-task__container--inner">
        <div class="sourcing_task__container--info">
            <p class="sourcing-task__info">Status: {{ statusName || status }}</p>
            <p v-if="assigned_staff.name" class="sourcing-task__info">Staff Name: {{ assigned_staff.name }}</p>
            <p class="sourcing-task__info">Candidate type: <span class="js-candidate-type-name"> {{ candidate_type.name }} </span> </p>
            <!-- <p class="sourcing-task__info">Requested: {{ candidate_type.requested || requested }}</p> -->
        </div>
        <form-field
            title="Requested"
            fieldtype="form-number"
            v-bind:inputname="`task_${id}_requested`"
            addclass="sourcing-task__info"
            v-bind:startvalue="candidate_type.requested || requested"
            v-bind:alloptions="{min: 1, max: 9999999999, step: 1}"
            v-bind:isdisabled="!viewer_is_owner"
            v-on:event-bubble="handleRequestedChange"
        ></form-field>

        <button 
            v-if="$parent.$parent.notenew" 
            class="single-note__button--sourcing-task single-note__button--delete" 
            v-on:click="getDeleteTaskIndex($event)">
            <i class="flaticon-delete"></i>
        </button>
        <!-- <button v-if="showAcceptButton" class="single-note__button--sourcing-task" v-on:click="acceptTask">Accept</button> -->
        <!-- <button 
            v-if="showCommentsButton" 
            class="single-note__button--sourcing-task" 
            v-on:click="toggleComments">
            <i class="flaticon-comment"></i>
        </button> -->
        <comments
            v-if="!$parent.$parent.notenew"
            v-bind:sourcingtaskid="this.id"
            v-bind:jobrequestid="null"
        ></comments>
        <!-- <div v-if="showComments" class="comments__container comment-form">
            <h3>Comments</h3>

            <div v-for="comment in comments" v-bind:key="comment.id" class="comment__container--single">
                
                <p><b>{{ comment.staff.name }}</b>: {{ comment.comment }}</p>
                <p><span>Delivered:</span> {{ comment.delivered }}</p>
                <p><span>Approved:</span> {{ comment.approved }}</p>
                <p><span>Rejected:</span> {{ comment.rejected }}</p>
            </div>

            <button class="single-note__button--sourcing-task" v-on:click="toggleComments">Hide</button>
        </div> -->
    </div>
</template>

<script>
    import Ajax from '../../form-field/sub-components/js/ajax.js'
    import FormField from '../../form-field/form-field.vue'
    import Comments from '../shared/comments.vue';

    export default {
        props: [
            'taskdata',
            'isowner',
            'ismember',
        ],
        data: function(){
            return {
                id: this.taskdata.id,
                status: this.taskdata.status || 'unassigned',
                statusName: this.taskdata.status_display_name,
                requested: this.taskdata.requested,
                assigned_staff: this.taskdata.assigned_staff || { id: null, name: null },
                candidate_type: this.taskdata.candidate_type || { id: null, name: '', short_name: '', requested: 0 },
                viewer_is_member: this.ismember,
                viewer_is_owner: this.isowner,
                // showAcceptButton: this.checkAcceptButton(),
                showAcceptButton: false,
                taskDetails: null,
            }
        },
        created: function() {
            // this.showAcceptButton = this.checkAcceptButton();
        },
        methods: {
            // acceptTask: function(){
            //     Ajax.request('POST',{},`/sourcing-tasks/${this.id}/accept`,(response) => {
            //         // console.log(response);
            //         this.status = 'assigned';
            //         window.location.reload();
            //     });
            // },
            // checkAcceptButton: function(){
            //     return this.viewer_is_member && !this.$parent.$parent.notenew && this.status == 'unassigned';
            // },
            handleRequestedChange: function(event){
                this.requested = event.data;
                this.$emit('changeSourcingTaskRequested',{ data: {
                    requested: event.data,
                    id: this.id
                }});
            },
            getDeleteTaskIndex: function(event) {
                const allSourcingTasksArray = Array.from(this.$parent.$el.querySelectorAll('.sourcing-task__container--single'));
                const currentSourcingTaskContainer = event.target.parentElement.parentElement;
                const currentSourcingTaskContainerIndex = allSourcingTasksArray.indexOf(currentSourcingTaskContainer);
                const currentSourcingTaskCandidateTypeName = currentSourcingTaskContainer.querySelector('.js-candidate-type-name').innerText;

                this.$emit('event-bubble', { sourcingTaskIndex: currentSourcingTaskContainerIndex, sourcingTaskCandidateTypeName: currentSourcingTaskCandidateTypeName });
            }
        },
        components: { Ajax, FormField, Comments }
    }
</script>