<template>
    <div class="note__container--sourcing-tasks">
        <div 
            v-if="$parent.notenew"
            class="sourcing-task__container--create">
            <div class="container--header">
                <h4> Create </h4>
            </div>

            <div 
                class="single-sourcing-task__container--inner">
                <form-field
                    title="Candidate type"
                    fieldtype="form-dropdown"
                    inputname="candidate_type"
                    addclass="sourcing-task-new"
                    v-bind:startvalue="1"
                    v-bind:alloptions="candidatetypeoptions"
                    v-on:event-bubble="handleCandidateTypeChange"
                ></form-field>

                <form-field
                    title="Request"
                    fieldtype="form-number"
                    inputname="candidate_requested"
                    addclass="sourcing-task-new"
                    v-bind:startvalue="1"
                    v-bind:alloptions="{min: 1, max: 9999999999, step: 1}"
                ></form-field>
                <button
                    class="single-note__button--sourcing-task"
                    v-bind:class="{ 'button--disabled': disableCreateTaskButton }" 
                    v-on:click="createNewTask">
                    <i class="flaticon-add-circular-button"></i>
                </button>
            </div>
            <p
                v-if="disableCreateTaskButton"
                class="sourcing-task__text--candidate-type-created">
                Sourcing task for this candidate type is already created. If you want to create new sourcing task, please select another candidate type.
            </p>
        </div>

        <div
            v-if="tasklist.length > 0" 
            class="sourcing-task__container--tasks">
            <div class="container--header">
                <h4> List </h4>
            </div>

            <div class="sourcing-task_container--tasks-outer">
                <div
                    class="sourcing-task__container--single"
                    v-for="task in tasklist" 
                    v-bind:key="task.id ? task.id : task.status + task.candidate_type.id">
                    <sourcing-task
                        v-bind:ismember="isMember"
                        v-bind:isowner="isOwner"
                        v-bind:taskdata="task"
                        v-on:changeSourcingTaskRequested="changeSourcingTaskRequested"
                        v-on:event-bubble="deleteTask"
                    ></sourcing-task>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SourcingTask from '../shared/sourcing-task.vue'
    import FormField from '../../form-field/form-field.vue'

    export default {
        props: [
            'ismember',
            'isowner',
            'sourcingtasks',
            'candidatetypeoptions'
        ],
        data: function(){
            return {
                tasklist: this.sourcingtasks || [],
                disableCreateTaskButton: false,
                isMember: this.ismember,
                isOwner: this.isowner,
            }
        },
        methods: {
            createNewTask: function(){
                console.log(this.$children[0],this.$children[1]);

                this.tasklist.push({
                    status: 'unassigned',
                    candidate_type: {
                        id: this.$children[0].$children[0].selectedOption.id,
                        name: this.$children[0].$children[0].selectedOption.name,
                        requested: this.$children[1].$children[0].numberValue
                    }
                });

                this.setCreateButtonDisable(true);
            },
            handleCandidateTypeChange: function(event){
                console.log(`CANDIDATE TYPE CHANGE`,event);

                this.setCreateButtonDisable(false,event.data.name);
            },
            setCreateButtonDisable: function(hide,checkName){
                if(hide){
                    this.disableCreateTaskButton = true;
                    return;
                };

                if(checkName){
                    this.disableCreateTaskButton = this.tasklist.filter((Task) => {
                        return Task.candidate_type.name == checkName;
                    }).length;
                };
            },
            changeSourcingTaskRequested(event){
                this.tasklist = this.tasklist.map((Task) => {
                    if(Task.id == event.data.id){
                        Task.requested = event.data.requested;
                    };
                    
                    return Task;
                });
            },
            deleteTask: function(event) {
                const candidateTypeNameFromDropdown = this.$children[0].$children[0].$el.__vue__.selectedOption.name;
                const filteredTaskListCount = this.tasklist.filter(task => task.candidate_type.name === candidateTypeNameFromDropdown).length;

                if (candidateTypeNameFromDropdown === event.sourcingTaskCandidateTypeName) {
                    this.disableCreateTaskButton = false;
                } else {
                    if (filteredTaskListCount === 0) {
                        this.disableCreateTaskButton = false;
                    } else {
                        this.disableCreateTaskButton = true;
                    }
                }

                this.tasklist.splice(event.sourcingTaskIndex, 1);
            }
        },
        components: {
            SourcingTask,
            FormField
        }
    }
</script>