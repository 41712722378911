<template>
    <div class="profile__container--wrapper profile__container--columns-3">

            <div class="profile__container--outer candidate-details sectioned profile__container--columnPos-1">
                <div class="profile__container--header">
                    <h3></h3>
                </div>
                <div class="profile__container--main">
                    <form-field
                        title="Type"
                        fieldtype="form-dropdown"
                        inputname="job_request[request_type]"
                        v-bind:startvalue="jobRequest.request_type"
                        v-bind:alloptions="formOptions.request_type_options"
                        v-bind:haserrors="jobRequest.errors.request_type"
                        v-bind:isdisabled="restrictedKeys.includes('request_type')"
                    ></form-field>

                    <form-field
                        title="Main Recruiter"
                        fieldtype="form-dropdown"
                        inputname="job_request[managed_by_staff_id]"
                        v-bind:startvalue="jobRequest.managed_by_staff"
                        searchurl="/autocomplete/staff"
                        v-bind:haserrors="jobRequest.errors.managed_by_staff_id"
                        v-bind:isdisabled="restrictedKeys.includes('managed_by_staff_id')"
                        preload='1'
                    ></form-field>

                    <form-field
                        title="Shared with"
                        fieldtype="form-dropdown"
                        inputname="job_request[shared_with_staff_id]"
                        v-bind:startvalue="jobRequest.shared_with_staff"
                        searchurl="/autocomplete/staff"
                        addblank="true"
                        v-bind:haserrors="jobRequest.errors.shared_with_staff_id"
                        v-bind:isdisabled="restrictedKeys.includes('shared_with_staff_id')"
                        preload='1'
                    ></form-field>

                    <div class="profile__container--field field--padding">
                    </div>

<!--                     <form-field
                        title="Location BL"
                        fieldtype="form-dropdown"
                        inputname="job_request[bluelynx_location_id]"
                        v-bind:startvalue="jobRequest.bluelynx_location"
                        v-bind:alloptions="formOptions.bluelynx_location_options"
                        v-bind:haserrors="jobRequest.errors.bluelynx_location_id"
                        v-bind:isdisabled="restrictedKeys.includes('bluelynx_location_id')"
                    ></form-field> -->

                    <form-field
                        title="Request No."
                        fieldtype="form-input"
                        inputname="job_request[request_nr]"
                        v-bind:startvalue="jobRequest.request_nr"
                        v-bind:haserrors="jobRequest.errors.request_nr"
                        isdisabled="true"
                        v-bind:isdisabled="restrictedKeys.includes('request_nr')"
                    ></form-field>

                    <form-field
                        title="Request date"
                        fieldtype="form-date"
                        inputname="job_request[request_date]"
                        v-bind:startvalue="jobRequest.request_date"
                        v-bind:haserrors="jobRequest.errors.request_date"
                        v-bind:isdisabled="restrictedKeys.includes('request_date')"
                    ></form-field>

                    <form-field
                        title="Job title"
                        fieldtype="form-input"
                        inputname="job_request[job_title]"
                        v-bind:startvalue="jobRequest.job_title"
                        v-bind:haserrors="jobRequest.errors.job_title"
                        v-bind:isdisabled="restrictedKeys.includes('job_title')"
                    ></form-field>


                    <div class="profile__container--row salary-row--flip">
                        <form-field
                            title="Salary min"
                            fieldtype="form-number"
                            inputname="job_request[salary_min]"
                            v-bind:startvalue="jobRequest.salary_min"
                            v-bind:alloptions="{
                                min: 0,
                                max: 9999999999,
                                step: 1
                            }"
                            v-bind:haserrors="jobRequest.errors.salary_min"
                            v-bind:isdisabled="restrictedKeys.includes('salary_min')"
                        ></form-field>

                        <form-field
                            title="Salary max"
                            fieldtype="form-number"
                            inputname="job_request[salary_max]"
                            v-bind:startvalue="jobRequest.salary_max"
                            v-bind:alloptions="{
                                min: 0,
                                max: 9999999999,
                                step: 1
                            }"
                            v-bind:haserrors="jobRequest.errors.salary_max"
                            v-bind:isdisabled="restrictedKeys.includes('salary_max')"
                        ></form-field>
                    </div>

                    <div class="profile__container--row salary-row--flip">
                        <form-field
                            title="Salary currency"
                            fieldtype="form-dropdown"
                            inputname="job_request[salary_currency]"
                            v-bind:startvalue="jobRequest.salary_currency"
                            v-bind:alloptions="formOptions.salary_currency_options"
                            v-bind:haserrors="jobRequest.errors.salary_currency"
                            v-bind:isdisabled="restrictedKeys.includes('salary_currency')"
                        ></form-field>

                         <form-field
                            title="Salary range"
                            fieldtype="form-radio"
                            inputname="job_request[salary_range_type]"
                            v-bind:startvalue="jobRequest.salary_range_type"
                            v-bind:alloptions="formOptions.salary_range_type_options"
                            v-bind:haserrors="jobRequest.errors.salary_range_type"
                            v-bind:isdisabled="restrictedKeys.includes('salary_range_type')"
                        ></form-field>
                    </div>

                    <form-field
                        title="Salary overrule"
                        fieldtype="form-dropdown"
                        inputname="job_request[salary_overrule_id]"
                        v-bind:startvalue="jobRequest.salary_overrule_id"
                        v-bind:alloptions="formOptions.salary_overrule_options"
                        v-bind:haserrors="jobRequest.errors.salary_overrule"
                        addblank="true"
                        v-bind:isdisabled="restrictedKeys.includes('salary_overrule_id')"
                    ></form-field>

                    <div class="profile__container--row date-row">
                        <form-field
                            title="Start date"
                            fieldtype="form-date"
                            inputname="job_request[start_date]"
                            v-bind:startvalue="jobRequest.start_date"
                            v-bind:haserrors="jobRequest.errors.start_date"
                            v-bind:isdisabled="restrictedKeys.includes('start_date')"
                        ></form-field>

                        <form-field
                            title="End date"
                            fieldtype="form-date"
                            inputname="job_request[end_date]"
                            v-bind:startvalue="jobRequest.end_date"
                            v-bind:haserrors="jobRequest.errors.end_date"
                            v-bind:isdisabled="restrictedKeys.includes('end_date')"
                        ></form-field>
                    </div>

                <form-field
                    title="ASAP"
                    fieldtype="form-checkbox"
                    inputname="job_request[is_asap]"
                    v-bind:startvalue="jobRequest.is_asap"
                    v-bind:haserrors="jobRequest.errors.is_asap"
                    v-bind:isdisabled="restrictedKeys.includes('is_asap')"
                ></form-field>


                <!-- closing Details main -->
                </div>
            <!-- closing Details outer -->
            </div>

            <div class="profile__container--outer candidate-work sectioned profile__container--columnPos-2">
                <div class="profile__container--header">
                    <h3></h3>
                </div>
                <div class="profile__container--main">
                    <form-field
                        title="Categories"
                        fieldtype="form-multidrop"
                        inputname="job_request[category_ids][]"
                        v-bind:startvalue="jobRequest.categories"
                        searchurl="/autocomplete/job-request-categories"
                        v-bind:haserrors="jobRequest.errors.category_ids"
                        v-bind:isdisabled="restrictedKeys.includes('category_ids')"
                    ></form-field>

                    <form-field
                        title="Highlighted"
                        fieldtype="form-checkbox"
                        inputname="job_request[highlighted]"
                        v-bind:startvalue="jobRequest.highlighted"
                        v-bind:haserrors="jobRequest.errors.highlighted"
                        v-bind:isdisabled="restrictedKeys.includes('highlighted')"
                    ></form-field>

                    <form-field
                        title="Country"
                        fieldtype="form-dropdown"
                        inputname="job_request[country_id]"
                        v-bind:startvalue="jobRequest.country"
                        searchurl="/autocomplete/countries"
                        addblank="true"
                        v-bind:haserrors="jobRequest.errors.country_id"
                        v-bind:isdisabled="restrictedKeys.includes('country_id')"
                        preload='1'
                    ></form-field>
                    
                    <form-field
                        title="Location"
                        fieldtype="form-input"
                        inputname="job_request[location]"
                        v-bind:startvalue="jobRequest.location"
                        v-bind:haserrors="jobRequest.errors.location"
                        v-bind:isdisabled="restrictedKeys.includes('location')"
                    ></form-field>

                   
                    <form-field
                        title="Hours per week"
                        fieldtype="form-number"
                        inputname="job_request[hours_per_week]"
                        v-bind:startvalue="jobRequest.hours_per_week"
                        v-bind:alloptions="{
                            min: 0,
                            max: 168,
                            step: 1
                        }"
                        v-bind:haserrors="jobRequest.errors.hours_per_week"
                        v-bind:isdisabled="restrictedKeys.includes('hours_per_week')"
                    ></form-field>

                    <div class="profile__container--row">
                        <form-field
                            title="BL Rate (min)"
                            fieldtype="form-number"
                            inputname="job_request[hourly_rate_min]"
                            v-bind:startvalue="jobRequest.hourly_rate_min"
                            v-bind:alloptions="{
                                min: 0,
                                max: 9999999999,
                                step: 1
                            }"
                            v-bind:haserrors="jobRequest.errors.hourly_rate_min"
                            v-bind:isdisabled="restrictedKeys.includes('hourly_rate_min')"
                        ></form-field>

                        <form-field
                            title="BL Rate (max)"
                            fieldtype="form-number"
                            inputname="job_request[hourly_rate_max]"
                            v-bind:startvalue="jobRequest.hourly_rate_max"
                            v-bind:alloptions="{
                                min: 0,
                                max: 9999999999,
                                step: 1
                            }"
                            v-bind:haserrors="jobRequest.errors.hourly_rate_max"
                            v-bind:isdisabled="restrictedKeys.includes('hourly_rate_max')"
                        ></form-field>
                    </div>


                    <form-field
                        title="Type of Contract"
                        fieldtype="form-dropdown"
                        inputname="job_request[job_contract_type_id]"
                        v-bind:startvalue="jobRequest.job_contract_type_id"
                        v-bind:alloptions="formOptions.job_contract_type_options"
                        v-bind:haserrors="jobRequest.errors.job_contract_type_id"
                        v-bind:isdisabled="restrictedKeys.includes('job_contract_type_id')"
                    ></form-field>

                    <form-field
                        title="Request status"
                        fieldtype="form-dropdown"
                        inputname="job_request[job_request_status_id]"
                        v-bind:startvalue="jobRequest.job_request_status_id"
                        v-bind:alloptions="formOptions.job_request_status_options"
                        v-bind:haserrors="jobRequest.errors.job_request_status" v-bind:isdisabled="restrictedKeys.includes('job_request_status_id')"
                    ></form-field>

                    <form-field
                        title="Duration"
                        fieldtype="form-dropdown"
                        inputname="job_request[job_duration_id]"
                        v-bind:startvalue="jobRequest.job_duration_id"
                        v-bind:alloptions="formOptions.job_duration_options"
                        v-bind:haserrors="jobRequest.errors.job_duration_id"
                        addblank="true"
                        v-bind:isdisabled="restrictedKeys.includes('job_duration_id')"
                    ></form-field>

                    <!-- Languages -->

                    <job-request-languages
                        addWrapperClasss="profile__container--columnPos-1"
                        v-bind:intial-records="jobRequest.languages"
                        v-bind:language-proficiency-options="formOptions.language_proficiency_options"
                        v-bind:isdisabled="restrictedKeys.includes('job_request_languages_attributes')"
                    ></job-request-languages>

                    <form-field
                        title="Please publish to websites"
                        fieldtype="form-checkbox"
                        inputname="job_request[publish_to_website]"
                        v-bind:startvalue="jobRequest.publish_to_website"
                        v-bind:haserrors="jobRequest.errors.publish_to_website"
                        v-bind:isdisabled="restrictedKeys.includes('publish_to_website')"
                    ></form-field>
                    <form-field
                        title="Please publish to feeds"
                        fieldtype="form-checkbox"
                        inputname="job_request[publish_to_feeds]"
                        v-bind:startvalue="jobRequest.publish_to_feeds"
                        v-bind:haserrors="jobRequest.errors.publish_to_feeds"
                        v-bind:isdisabled="restrictedKeys.includes('publish_to_feeds')"
                    ></form-field>
                    <form-field
                        title="Publish on"
                        fieldtype="form-multidrop"
                        inputname="job_request[publish_on_ids][]"
                        v-bind:startvalue="jobRequest.publish_ons"
                        v-bind:alloptions="formOptions.publish_on_options"
                        v-bind:haserrors="jobRequest.errors.publish_on_ids"
                        v-bind:isdisabled="restrictedKeys.includes('publish_on_ids')"
                    ></form-field>
                <!-- closing work main div -->
                </div>
            <!-- closing work outer div -->
            </div>

            <div v-if="company.id" class="profile__container--outer job-request-company sectioned profile__container--status profile__container--columnPos-3 profile__container--rowPos-2">
                <div class="profile__container--header">
                    <h3>Company details</h3>
                </div>
                <div class="profile__container--main">
                    <div>
                        <p>Company No.</p> <a v-bind:href="company.details_link">{{ company.client_no }}</a>
                    </div>
                    <div>
                        <p>Company Name</p> <p>{{ company.name }}</p>
                    </div>
                    <div>
                        <p>Visiting Address</p> <p>{{ company.va_address }}</p>
                    </div>
                    <div>
                        <p>Visiting House No.</p> <p>{{ company.va_housenumber }}</p>
                    </div>
                    <div>
                        <p>Postcode</p> <p>{{ company.va_postcode }}</p>
                    </div>
                    <div>
                        <p>City</p> <p>{{ company.va_town }}</p>
                    </div>
                    <div>
                        <p>Direct</p> <p>{{ company.phone }}</p>
                    </div>
                    <div>
                        <p>E-mail</p> <p>{{ company.email }}</p>
                    </div>
                    <div>
                        <p>Website</p> <p>{{ company.website }}</p>
                    </div>
                    <div>
                        <p>Type</p> <p>{{ company.type }}</p>
                    </div>

                    <form-field
                        title="Contact"
                        fieldtype="form-dropdown"
                        inputname="job_request[company_contact_id]"
                        v-on:event-bubble="handleContactChange"
                        v-bind:startvalue="jobRequest.company_contact_id"
                        v-bind:alloptions="company.contacts"
                        v-bind:haserrors="jobRequest.errors.company_contact"
                        v-bind:isdisabled="restrictedKeys.includes('company_contact_id')"
                    ></form-field>

                    <div> 
                        <p>Name</p> <p>{{ contact.name }}</p>
                    </div>
                    <div> 
                        <p>Department</p> <p>{{ contact.position }}</p>
                    </div>
                    <div> 
                        <p>Job Title</p> <p>{{ contact.department }}</p>
                    </div>
                    <div> 
                        <p>Phone</p> <p>{{ contact.direct_phone }}</p>
                    </div>
                    <div> 
                        <p>Mobile</p> <p>{{ contact.mobile_number }}</p>
                    </div>
                    <div> 
                        <p>E-mail</p> <p>{{ contact.email_business }}</p>
                    </div>

                    <br>
                    <div>Contact history</div>
                    <div 
                        v-for="(record, index) in contactChanges"
                        v-bind:key="contactChanges.id">

                        {{ record.created_at }} <a v-bind:href="record.details_link">{{ record.contact_name }}</a>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'
    import JobRequestLanguages from './shared/job-request-languages.vue'

    export default {
        props: [
            'jobRequestData',
            'formOptionsData'
        ],
        data: function(){
            var jobRequest = JSON.parse(this.jobRequestData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');
            var restrictedKeys = formOptions.restricted_keys || [];
            var company = jobRequest.company || {};
            var contact = (company.contacts || []).find(c => c.id === jobRequest.company_contact_id) || {};
            var contactChanges = (company.contact_changes || []);

            console.log(jobRequest);
            console.log(formOptions);

            return { 
                jobRequest: jobRequest,
                company: company,
                contact: contact, 
                contactChanges: contactChanges,
                formOptions: formOptions,
                restrictedKeys: restrictedKeys
            };
        },
        components: {
            FormField,
            JobRequestLanguages
        },
        methods: {
            handleContactChange: function(event){ 
                this.$nextTick(() => {
                    this.contact = event.data;
                    console.log(this.contact);
                });
                
            }
        }
    }
</script>