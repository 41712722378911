<template>
    <div class="profile__container--wrapper profile__container--columns-2">
        <div class="profile__container--outer candidate-details sectioned">
            <div class="profile__container--header">
                <h3>New Candidate</h3>
            </div>
            <div class="profile__container--main">
                <form-field
                    title="Account Type"
                    fieldtype="form-dropdown"
                    inputname="candidate[candidate_type_id]"
                    v-bind:startvalue="candidate.candidate_type"
                    v-bind:alloptions="formOptions.candidate_type_options"
                    v-bind:haserrors="candidate.errors.candidate_type_id"
                ></form-field>
                <!-- To do - avatar upload -->
                <!-- Registration number - readonly - in the info section?  -->

                <form-field
                    title="First name"
                    fieldtype="form-input"
                    inputname="candidate[first_name]"
                    v-bind:startvalue="candidate.first_name"
                    v-bind:haserrors="candidate.errors.first_name"
                ></form-field>


                <form-field
                    title="Middle name"
                    fieldtype="form-input"
                    inputname="candidate[middle_name]"
                    v-bind:startvalue="candidate.middle_name"
                    v-bind:haserrors="candidate.errors.middle_name"
                ></form-field>

                <form-field
                    title="Last name preposition"
                    fieldtype="form-input"
                    inputname="candidate[last_name_preposition]"
                    v-bind:startvalue="candidate.last_name_preposition"
                    v-bind:haserrors="candidate.errors.last_name_preposition"
                ></form-field>

                <form-field
                    title="Last name"
                    fieldtype="form-input"
                    inputname="candidate[last_name]"
                    v-bind:startvalue="candidate.last_name"
                    v-bind:haserrors="candidate.errors.last_name"
                ></form-field>

                <form-field
                    title="Initials"
                    fieldtype="form-input"
                    inputname="candidate[initials]"
                    v-bind:startvalue="candidate.initials"
                    v-bind:haserrors="candidate.errors.initials"
                ></form-field>

                <form-field
                    title="Gender"
                    fieldtype="form-dropdown"
                    inputname="candidate[gender]"
                    v-bind:startvalue="candidate.gender"
                    v-bind:alloptions="formOptions.gender_options"
                    v-bind:haserrors="candidate.errors.gender"
                ></form-field>

                <form-field
                    title="Date of birth"
                    fieldtype="form-date"
                    inputname="candidate[date_of_birth]"
                    v-bind:startvalue="candidate.date_of_birth"
                    v-bind:error="candidate.date_of_birth"
                    v-bind:haserrors="candidate.errors.date_of_birth"
                ></form-field>

                <form-field
                    title="Nationalities"
                    fieldtype="form-multidrop"
                    inputname="candidate[nationality_ids][]"
                    v-bind:startvalue="candidate.nationalities"
                    searchurl="/autocomplete/nationalities"
                    preload="1"
                    v-bind:haserrors="candidate.errors.nationality_ids"
                ></form-field>


                <candidate-address
                    v-bind:address-data="{ 
                        address: candidate.address, 
                        housenumber: candidate.housenumber, 
                        postcode: candidate.postcode, 
                        town: candidate.town, 
                        country: candidate.country, 
                        country_region: candidate.country_region,
                        province: candidate.province }"

                     v-bind:errors-data="{ 
                        address: candidate.errors.address, 
                        housenumber: candidate.errors.housenumber, 
                        postcode: candidate.errors.postcode, 
                        town: candidate.errors.town,
                        country: candidate.errors.country,
                        country_region: candidate.errors.country_region,
                        province: candidate.errors.province }"
                ></candidate-address>

                <form-field
                    title="Address / Street 2"
                    fieldtype="form-input"
                    inputname="candidate[address2]"
                    v-bind:startvalue="candidate.address2"
                    v-bind:haserrors="candidate.errors.address2"
                ></form-field>
               
                <form-field
                    title="Tel. Home"
                    fieldtype="form-input"
                    inputname="candidate[phone_home]"
                    v-bind:startvalue="candidate.phone_home"
                    v-bind:haserrors="candidate.errors.phone_home"
                ></form-field>

                <form-field
                    title="Tel. Mobile"
                    fieldtype="form-input"
                    inputname="candidate[phone_mobile]"
                    v-bind:startvalue="candidate.phone_mobile"
                    v-bind:haserrors="candidate.errors.phone_mobile"
                ></form-field>
               
                <form-field
                    title="Online profile URL"
                    fieldtype="form-input"
                    inputname="candidate[online_profile_url]"
                    v-bind:startvalue="candidate.online_profile_url"
                    v-bind:haserrors="candidate.errors.online_profile_url"
                ></form-field>
               
                <form-field
                    title="E-Mail"
                    fieldtype="form-input"
                    inputname="candidate[email_home]"
                    v-bind:startvalue="candidate.email_home"
                    v-bind:haserrors="candidate.errors.email_home"
                ></form-field>

            <!-- closing Details main -->
            </div>
        <!-- closing Details outer -->
        </div>
        

        <div class="profile__container--outer candidate-work sectioned">
            <div class="profile__container--header">
                <h3>Work Section Start?</h3>
            </div>
            <div class="profile__container--main">
                <form-field
                    title="Internal admission notes"
                    fieldtype="form-textarea"
                    inputname="candidate[int_admission_notes]"
                    v-bind:startvalue="candidate.int_admission_notes"
                    v-bind:haserrors="candidate.errors.int_admission_notes"
                ></form-field>

                <div class="profile__container--col">
                    <form-field
                        title="Blue Lynx location"
                        fieldtype="form-dropdown"
                        inputname="candidate[bluelynx_location_id]"
                        v-bind:startvalue="candidate.bluelynx_location"
                        v-bind:alloptions="formOptions.bluelynx_locations"
                        v-bind:haserrors="candidate.errors.bluelynx_location"
                    ></form-field>

                    <form-field
                        title="Intake"
                        fieldtype="form-dropdown"
                        inputname="candidate[intake]"
                        v-bind:startvalue="candidate.intake"
                        v-bind:alloptions="formOptions.intake_options"
                        v-bind:haserrors="candidate.errors.intake"
                    ></form-field>
                </div>

                <div class="profile__container--row salary-row--flip">
                    <form-field
                        title="Salary min"
                        fieldtype="form-number"
                        inputname="candidate[salary_range_min]"
                        v-bind:startvalue="candidate.salary_range_min"
                        v-bind:alloptions="{
                            min: 0,
                            max: 9999999999,
                            step: 1
                        }"
                        v-bind:haserrors="candidate.errors.salary_range_min"
                    ></form-field>

                    <form-field
                        title="Salary max"
                        fieldtype="form-number"
                        inputname="candidate[salary_range_max]"
                        v-bind:startvalue="candidate.salary_range_max"
                        v-bind:alloptions="{
                            min: 0,
                            max: 9999999999,
                            step: 1
                        }"
                        v-bind:haserrors="candidate.errors.salary_range_max"
                    ></form-field>
                </div>
                <div class="profile__container--row">
                    <form-field
                        title="Salary currency"
                        fieldtype="form-dropdown"
                        inputname="candidate[salary_currency]"
                        v-bind:startvalue="candidate.salary_currency"
                        v-bind:alloptions="formOptions.salary_currencies"
                        v-bind:haserrors="candidate.errors.salary_currency"
                    ></form-field>

                    <form-field
                        title="Salary range"
                        fieldtype="form-radio"
                        inputname="candidate[salary_range_type]"
                        v-bind:startvalue="candidate.salary_range_type"
                        v-bind:alloptions="formOptions.salary_range_types"
                        v-bind:haserrors="candidate.errors.salary_range_type"
                    ></form-field>
                </div>

                <div class="profile__container--row">
                    <form-field
                        title="Includes vacation"
                        fieldtype="form-checkbox"
                        inputname="candidate[salary_includes_vacation]"
                        v-bind:startvalue="candidate.salary_includes_vacation"
                        v-bind:haserrors="candidate.errors.salary_includes_vacation"
                    ></form-field>

                    <form-field
                        title="Is negotiable"
                        fieldtype="form-checkbox"
                        inputname="candidate[salary_is_negotiable]"
                        v-bind:startvalue="candidate.salary_is_negotiable"
                        v-bind:haserrors="candidate.errors.salary_is_negotiable"
                    ></form-field>
                </div>

                <div class="profile__container--row">
                    <form-field
                        title="Freelancer interest"
                        fieldtype="form-checkbox"
                        inputname="candidate[freelancer_interest]"
                        v-bind:startvalue="candidate.freelancer_interest"
                        v-bind:haserrors="candidate.errors.freelancer_interest"
                    ></form-field>

                    <form-field
                        title="Freelancer day rate"
                        fieldtype="form-number"
                        inputname="candidate[freelancer_day_rate]"
                        v-bind:startvalue="candidate.freelancer_day_rate"
                        v-bind:alloptions="{
                            min: 0,
                            max: 9999999999,
                            step: 1
                        }"
                        v-bind:haserrors="candidate.errors.freelancer_day_rate"
                    ></form-field>
                </div>

                <div class="profile__container--row">
                    <form-field
                        title="Type of employment"
                        fieldtype="form-dropdown"
                        inputname="candidate[type_of_employment]"
                        v-bind:startvalue="candidate.type_of_employment"
                        v-bind:alloptions="formOptions.type_of_employment_options"
                        v-bind:haserrors="candidate.errors.type_of_employment"
                    ></form-field>

                    <form-field
                        title="Job Type"
                        fieldtype="form-dropdown"
                        inputname="candidate[full_parttime]"
                        v-bind:startvalue="candidate.full_parttime"
                        v-bind:alloptions="formOptions.full_parttime_options"
                        v-bind:haserrors="candidate.errors.full_parttime"
                    ></form-field>
                </div>

                <form-field
                    title="Max. Commute Time"
                    fieldtype="form-dropdown"
                    inputname="candidate[travelling_time_id]"
                    v-bind:startvalue="candidate.travelling_time_id"
                    v-bind:alloptions="formOptions.travelling_time_options"
                    v-bind:haserrors="candidate.errors.travelling_time"
                ></form-field>
        
                <form-field
                    title="Contractor BLE"
                    fieldtype="form-dropdown"
                    inputname="candidate[works_for_ble]"
                    v-bind:startvalue="candidate.works_for_ble"
                    v-bind:alloptions="formOptions.works_for_ble_options"
                    v-bind:haserrors="candidate.errors.works_for_ble"
                ></form-field>

                <form-field
                    title="Contract End Date"
                    fieldtype="form-date"
                    inputname="candidate[end_date_contract]"
                    v-bind:startvalue="candidate.end_date_contract"
                    v-bind:haserrors="candidate.errors.end_date_contract"
                ></form-field>

                <form-field
                    title="Heard of BL"
                    fieldtype="form-dropdown"
                    inputname="candidate[heard_of_blue_lynx_id]"
                    v-bind:startvalue="candidate.heard_of_blue_lynx"
                    searchurl="/autocomplete/heard-of-blue-lynxes"
                ></form-field>
            <!-- closing work main div -->
            </div>
        <!-- closing work outer div -->
        </div>


    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'
    import CandidateAddress from './shared/candidate-address.vue'
    import CandidateLanguages from './shared/candidate-languages.vue'
    import CandidateEducationItems from './shared/candidate-education-items.vue'
    import CandidateJobExperienceItems from './shared/candidate-job-experience-items.vue'

    export default {
        props: [
            'candidateData',
            'formOptionsData'
        ],
        data: function(){
            var candidate = JSON.parse(this.candidateData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');

            // console.log(candidate.salary_currency);
            // console.log(formOptions.salary_currencies);

            // console.log(candidate);
            // console.log(formOptions.gender_options);
            return { 
                candidate: candidate, 
                formOptions: formOptions,
                showCountryRegions: false, 
                regionsForCountry: ''
            };
        },
        components: {
            FormField,
            CandidateLanguages,
            CandidateEducationItems,
            CandidateJobExperienceItems,
            CandidateAddress
        }
    }
</script>